import React, {useState, useEffect, useCallback} from 'react'
import {css} from '@emotion/react'
import {IconSearch, IconXSmall} from '@kensho/icons'
import {navigate} from 'gatsby'
import {debounce} from 'lodash'

import useMultiSearchIndex, {MultiSearchIndexResult, Indices} from '../hooks/useMultiSearchIndex'
import useClickOutside from '../hooks/useClickOutside'
import normalizePath from '../utils/normalizePath'

import Popover from './Popover'
import SearchResult from './SearchResult'

interface SearchBarProps {
  pathPrefix?: string
  indices?: Indices
}

const inputGroupCss = css`
  position: relative;
  color: rgba(255, 255, 255, 0.54);
`

const desktopInputGroupCss = css`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const mobileInputGroupCss = css`
  height: 50px;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`

const inputIconCss = css`
  color: rgba(255, 255, 255, 0.54);
  position: absolute;
  top: 10px;
  left: 12px;
`
const mobileIconCss = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  padding: 0 8px;
`

const closeButtonCss = css`
  color: rgba(255, 255, 255, 0.54);
  position: absolute;
  top: 0;
  right: 4px;
  cursor: pointer;
  padding: 6px;
`

const mobileCloseButtonCss = css`
  top: 4px;
`

const inputCss = css`
  min-width: 360px;
  line-height: 34px;
  height: 36px;
  background: #41474b;
  color: #ffffff;
  font-size: 16px;
  padding: 0 36px 0 42px;
  box-sizing: border-box;
  border-radius: 2px;
  outline: none;
  border: 1px solid transparent;

  &::placeholder {
    color: rgba(255, 255, 255, 0.54);
    font-style: italic;
  }

  &:focus {
    border-color: #50afc6;
  }

  &::-ms-clear {
    display: none;
  }
`

const inputMobileCss = css`
  min-width: 400px;
  height: 50px;
  border-radius: 0px;
  position: absolute;
  top: 0px;
  right: 0px;

  @media screen and (max-width: 600px) {
    min-width: auto;
    width: 100vw;
  }

  &:focus {
    border-color: transparent;
  }
`

const mobileInputWrapperCss = css`
  position: relative;
`

const popoverCss = css`
  @media screen and (max-width: 1024px) {
    margin-top: 50px;
    width: 400px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
  }
  max-height: 400px;
  min-width: 400px;
  overflow: auto;
`

const searchResultsCss = css`
  margin: 0;
  list-style-type: none;
  max-width: 900px;
`

const noResultsCss = css`
  display: flex;
  margin: 10px 14px;
`

const noResultsIconCss = css`
  color: #50afc6;
  margin: 10px 14px 0 0;
`

const noResultsTextCss = css`
  margin-top: 12px;
  max-width: 400px;
  word-break: break-all;
`

const noResultsFoundCss = css`
  font-size: 16px;
`

const noResultsSubTextCss = css`
  margin: 0;
  font-size: 12px;
`

const searchButtonCss = css`
  display: flex;
  align-items: center;
  position: relative;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.72);
  outline: none;
  text-decoration: none;
  font-size: 16px;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.1s linear;
  border: none;
  border-left: 1px solid black;

  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    color: white;
    &:after {
      background-color: rgba(255, 255, 255, 0.54);
    }
  }
`

const activeSearchButtonCss = css`
  background-color: #41474b;
  color: white;

  &:hover {
    background-color: #41474b;
  }
`

const sendQueryAnalytics = debounce<(query: string) => void>((q = '') => {
  const queryValue = q.trim().substring(0, 100)
  if (queryValue && window.plausible) {
    window.plausible('Search', {props: {query: queryValue}})
  }

  if (queryValue && window.gtag) {
    window.gtag('event', 'search', {
      query: queryValue,
    })
  }
}, 2000)

export default function SearchBar({
  pathPrefix = '',
  indices = [],
}: SearchBarProps): JSX.Element | null {
  const [mobileSearchbarOpen, setMobileSearchbarOpen] = useState(false)
  const [resultsOpen, setResultsOpen] = useState(false)
  const clickOutsideMobileSearchRef = useClickOutside(() => setMobileSearchbarOpen(false))
  const clickOutsideRef = useClickOutside(() => setResultsOpen(false))
  const clickOutsideMobileRef = useClickOutside(() => setResultsOpen(false))

  const [activeIndex, setActiveIndex] = useState(0)
  const [query, setQuery] = useState('')
  const [results, setResults] = useState<MultiSearchIndexResult[]>([])
  const {
    multiSearchIndex,
    loadSearchIndex: _loadSearchIndex,
    loading,
    loaded,
    error,
  } = useMultiSearchIndex({
    indices,
  })

  const loadSearchIndex = useCallback(() => {
    if (!(loading || loaded)) _loadSearchIndex()
  }, [loading, loaded, _loadSearchIndex])

  useEffect(() => {
    sendQueryAnalytics(query)
  }, [query])

  useEffect(() => {
    setActiveIndex(0)
    if (!query || !multiSearchIndex) {
      setResults([])
      setResultsOpen(false)
      return
    }
    const searchResults = multiSearchIndex.search(query)
    setResults(searchResults.slice(0, 10))
    setResultsOpen(true)
  }, [query, multiSearchIndex])

  const onSelect = useCallback(
    ({store: {url, prefixPath}}) => {
      setQuery('')
      navigate(normalizePath(`${prefixPath === false ? '' : pathPrefix}${url}`))
    },
    [pathPrefix]
  )
  const onKeyDown = useCallback(
    (event) => {
      if (resultsOpen && results.length) {
        switch (event.key) {
          case 'Enter':
            onSelect(results[activeIndex])
            break
          case 'ArrowDown':
            setActiveIndex((prevActiveIndex) => Math.min(results.length - 1, prevActiveIndex + 1))
            break
          case 'ArrowUp':
            setActiveIndex((prevActiveIndex) => Math.max(0, prevActiveIndex - 1))
            break
          case 'Escape':
            setResultsOpen(false)
            break
          default:
        }
      }
    },
    [activeIndex, onSelect, results, resultsOpen]
  )

  if (!indices.length) return null

  return (
    <Popover
      popoverCss={popoverCss}
      isOpen={resultsOpen}
      target={
        <div css={inputGroupCss} data-testid="mobile-search">
          <div ref={clickOutsideMobileSearchRef} css={mobileInputGroupCss}>
            <button
              onClick={() => setMobileSearchbarOpen((prev) => !prev)}
              type="button"
              css={[searchButtonCss, mobileSearchbarOpen && activeSearchButtonCss]}
            >
              <IconSearch css={mobileIconCss} />
            </button>
            {mobileSearchbarOpen && (
              <div css={mobileInputWrapperCss}>
                <input
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  ref={clickOutsideMobileRef}
                  css={[inputCss, inputMobileCss]}
                  type="text"
                  placeholder="Search…"
                  value={query}
                  onFocus={loadSearchIndex}
                  onChange={(event) => setQuery(event.target.value || '')}
                  onKeyDown={onKeyDown}
                />
                {!!query && (
                  <IconXSmall
                    css={[closeButtonCss, mobileCloseButtonCss]}
                    size={36}
                    onClick={() => setQuery('')}
                  />
                )}
              </div>
            )}
          </div>
          <div className="desktopInputGroup" css={desktopInputGroupCss}>
            <IconSearch css={inputIconCss} />
            <input
              data-testid="search-input"
              ref={clickOutsideRef}
              css={inputCss}
              type="text"
              placeholder="Search…"
              value={query}
              onFocus={loadSearchIndex}
              onChange={(event) => setQuery(event.target.value || '')}
              onKeyDown={onKeyDown}
            />
            {!!query && <IconXSmall css={closeButtonCss} size={36} onClick={() => setQuery('')} />}
          </div>
        </div>
      }
    >
      {results.length ? (
        <ul css={searchResultsCss}>
          {!error &&
            results.map((result, i) => (
              <SearchResult
                key={result.ref}
                active={activeIndex === i}
                result={result}
                onSelect={onSelect}
                onMouseEnter={() => setActiveIndex(i)}
              />
            ))}
        </ul>
      ) : (
        <div css={noResultsCss}>
          <IconSearch css={noResultsIconCss} size={30} />
          <div css={noResultsTextCss}>
            <strong css={noResultsFoundCss}>{`No results found for "${query}"`}</strong>
            <p css={noResultsSubTextCss}>
              You may want to try different keywords or check for typos.
            </p>
          </div>
        </div>
      )}
    </Popover>
  )
}
